/* Main Overrides */
:root {
    /* Font sizes */
    --rnf-font-size-xs: 0.75rem;
    --rnf-font-size-sm: 0.875rem;
    --rnf-font-size-md: 1rem;
    --rnf-font-size-lg: 1.125rem;
    --rnf-font-size-xl: 1.266rem;
    --rnf-font-size-2xl: 1.5rem;
    --rnf-font-size-3xl: 1.75rem;
  
    /* Spacing */
    --rnf-spacing-0: 0;
    --rnf-spacing-1: 6px;
    --rnf-spacing-2: 12px;
    --rnf-spacing-3: 18px;
    --rnf-spacing-4: 24px;
    --rnf-spacing-5: 30px;
    --rnf-spacing-6: 36px;
    --rnf-spacing-7: 42px;
    --rnf-spacing-8: 48px;
  
    /* Font weights */
    --rnf-font-weight-normal: 400;
    --rnf-font-weight-medium: 500;
    --rnf-font-weight-semibold: 600;
    --rnf-font-weight-bold: 700;
  
    /* Font family */
    --rnf-font-family-sanserif:  Inter, Roboto, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Ubuntu, "Helvetica Neue", sans-serif;
  
    /* Border radius */
    --rnf-border-radius-sm: 2px;
    --rnf-border-radius-md: 4px;
    --rnf-border-radius-lg: 8px;
  
    /* Shadows */
    --rnf-shadow-sm: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)';
    --rnf-shadow-md: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)';
  
    /* Colors */
    --rnf-color-white: #fff;
    --rnf-color-white-a-75: rgba(255, 255, 255, 0.75);
    --rnf-color-black: #000;
    --rnf-color-gray-900: #212121;
    --rnf-color-gray-800: #3c4257;
    --rnf-color-gray-700: #616161;
    --rnf-color-gray-600: #212121;
    --rnf-color-gray-500: #9e9e9e;
    --rnf-color-gray-400: #9ea0aa;
    --rnf-color-gray-300: #e0e0e0;
    --rnf-color-gray-200: #eeeeee;
    --rnf-color-gray-100: #f5f5f5;
    --rnf-color-brand-500: #f44336;
    --rnf-color-brand-700: #f44336;
    --rnf-color-brand-900: #c62828;
  
    /* Component specific colors */
    --rnf-unread-badge-bg-color: #c62828;
    --rnf-avatar-bg-color: #ef9a9a;
    --rnf-message-cell-unread-dot-bg-color: #ef9a9a;
    --rnf-message-cell-hover-bg-color: #f1f6fc;
  }

/* NotificationFeedPopover Overrides */
:root {
    --rnf-notification-feed-popover-max-w: 400px;
    --rnf-notification-feed-popover-min-w: 280px;
    --rnf-notification-feed-popover-height: 600px;
    --rnf-notification-feed-popover-bg-color: #fff;
    --rnf-notification-feed-popover-z-index: 999;
    --rnf-notification-feed-popover-arrow-size: 10px;
    --rnf-notification-feed-popover-border-radius: 8px;
  }

/* NotificationFeed Overrides */
:root {
    --rnf-notification-feed-header-height: 60px;
  }

  .rnf-dropdown {
    font-size: var(--rnf-font-size-md);
    font-weight: var(--rnf-font-weight-medium);
    color: var(--rnf-color-gray-400);
    position: relative;
  }
  .rnf-mark-all-as-read {
    border: none;
    background: transparent;
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 0;
    font-size: var(--rnf-font-size-sm);
    color: var(--rnf-color-gray-400);
    cursor: pointer;
  }
  .rnf-notification-feed__type {
    font-size: '1rem';
    font-weight: 600;
    color: var(--rnf-color-gray-600);
    margin-right: var(--rnf-spacing-2);
  }

/* NotificationCell Overrides */
:root {
    --rnf-avatar-bg-color: #ef9a9a;
    --rnf-avatar-size: 32px;
    --rnf-avatar-initials-font-size: var(--rnf-font-size-md);
    --rnf-avatar-initials-line-height: var(--rnf-font-size-lg);
    --rnf-avatar-initials-color: #fff;
    --rnf-notification-cell-border-bottom-color: rgb(224, 224, 224);
    --rnf-notification-cell-padding: var(--rnf-spacing-3);
    --rnf-notification-cell-active-bg-color: rgba(0, 0, 0, 0.04);
    --rnf-notification-cell-unread-dot-size: 8px;
    --rnf-notification-cell-unread-dot-bg-color: #fe4b25;
    --rnf-notification-cell-unread-dot-border-color: #fe4b25;
    --rnf-notification-cell-content-color: var(--rnf-color-gray-900);
    --rnf-notification-cell-content-font-size: var(--rnf-font-size-sm);
    --rnf-notification-cell-content-line-height: var(--rnf-font-size-lg);
    --rnf-archive-notification-btn-bg-color: var(--rnf-color-gray-400);
    --rnf-archive-notification-btn-bg-color-active: var(--rnf-color-gray-500);
  }

.rnf-notification-cell__unread-dot {
    top: var(--rnf-spacing-2);
    left: var(--rnf-spacing-2);
}

.rnf-tooltip {
    background-color: var(--rnf-color-gray-700);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: var(--rnf-spacing-1) var(--rnf-spacing-2);
    font-size: var(--rnf-font-size-xs);
    line-height: var(--rnf-font-size-s);
    font-weight: var(--rnf-font-weight-medium);
    transition: opacity 0.3s;
    z-index: 9999;
  }

  .rnf-notification-cell__content h1,
  .rnf-notification-cell__content h2,
  .rnf-notification-cell__content h3,
  .rnf-notification-cell__content h4,
  .rnf-notification-cell__content p {
    font-size: '0.875rem';
    color: var(--rnf-color-gray-600);
    font-weight: 500;
    margin-bottom: 0.5em;
  }

  .rnf-notification-cell__timestamp {
    display: block;
    color: var(--rnf-color-gray-500);
    font-size: '0.75rem';
    font-weight: 400;
    line-height: var(--rnf-font-size-lg);
  }